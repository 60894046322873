import React from 'react';
import { withTable } from 'libs/react-mpk';
import validationService from './validationService';
import t from 'counterpart';

class List extends React.Component{
  render(){
    return(
      <div className="flex mpk-layout column mpk-full height mpk-padding-N padding-all">
        {this.props.children}
      </div>
    )
  }
}

export default withTable((props, handle) => ({
  hint: {children: t.translate('routes.validation.hint.listText')},
  columns: [
    {label: t.translate('word.logType'), value: 'log_type', className:'mpk-min-width-M', show: true, isSearchable: true, isSortable: true, isDefaultSearchColumn: true},
    {label: t.translate('word.npwp'), value: 'npwp', show: true, isSearchable: true, isSortable: true},
    {
      label: t.translate('word.status'), value: d => (
        <div
          className={`mpk-full width mpk-align-center mpk-margin-S margin-top margin-bottom mpk-min-width-N mpk-max-width-N mpk-status outline ${(() => {
            switch(d.status.toLowerCase()){
              case 'valid':
                return 'progress'
              case 'success':
                return 'success'
              default:
                return 'warn'
            }
          })()}`}
        >
          {d.status}
        </div>
      ), valueName: 'status', type: 'func', className:'mpk-font-size-S uppercase', 
      show: true, isSearchable: true, isSortable: true
    },
    {label: t.translate('word.validateDate'), value: 'validate_date', type: 'date', show: true, isSearchable: false, isSortable: true, isDefaultSort: true},
    {label: t.translate('word.user'), value: 'user', show: true, isSearchable: true, isSortable: true},
  ],
  fetchData: (params) => (
    new Promise(async (resolve, reject) => {
      let { company } = props.auth;
      let { asInternalService } = props.global;
      try {
        let res = await validationService[asInternalService || company ? 'get' : 'getSupportIndex'](params);
        resolve({data:res.data, total:Number(res.headers['x-pagination-count'])})
      } catch (error) {
        reject(error)
      }
    })
  ),
  actions:[
    {
      label: 'Reload',
      iconClassName: 'mdi mdi-reload',
      onClick: () => props.tableActions.reload(),
      show: true
    }
  ],
  tableSettingProps:{
    showPeriods: true
  }
}))(List);