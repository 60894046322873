import React from 'react';
import { DialogForm, DateTimeField, DividerLabel } from 'libs/react-mpk';
import t from 'counterpart';
import moment from 'moment';

class Period extends React.Component{
  state = {
    startDate: null,
    endDate: null
  }

  componentDidUpdate(prevProps){
    if(!prevProps.visible && this.props.visible){
      let { startDate, endDate } = this.props.query;
      this.setState({
        startDate: startDate.toDate(),
        endDate: endDate.toDate()
      });
    }
  }

  onSubmit = (callback) => {
    const { startDate, endDate } = this.state;
    if(moment(endDate).isBefore(moment(startDate))){
      callback(true, t.translate('sentence.warning.period'), false)
    } else {
      this.props.onSubmit({startDate, endDate});
      callback();
    }
  }

  render(){
    let { visible, onCancel } = this.props;  
    return(
      <DialogForm
        title={t.translate('sentence.changePeriod')}
        visible={visible}
        onCancel={onCancel}
        onSubmit={this.onSubmit}
        dialogStyle={{
          width: '100%',
          maxWidth: 480
        }}
      >
        <DividerLabel label="Start Date"/>
        <DateTimeField
          label="Start Date"
          defaultValue={this.state.startDate}
          onChange={(date) => this.setState({startDate: date})}
        />

        <DividerLabel label="End Date"/>
        <DateTimeField
          label="End Date"
          defaultValue={this.state.endDate}
          onChange={(date) => this.setState({endDate: date})}
        />
      </DialogForm>
    )
  }
}

export default Period;