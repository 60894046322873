import React from 'react';
import { Commandbar, withTable, navService } from 'libs/react-mpk';
import institutionService from './institutionService';
import t from 'counterpart';
import Form from './Form';
import Users from './Users';
import { Button } from 'react-md';
import { utilsService } from 'services';

class List extends React.Component{
  showUser = item => {
    console.log(item);
  }
  render(){
    return(
      <div className="flex mpk-layout column mpk-full height">
        <Commandbar
          title={t.translate('routes.institution.title')}
          actions={[
             {
              label: 'Add',
              iconClassName: 'mdi mdi-plus',
              onClick: () => this.props.handle.showForm(true),
              show: true
            },
            {
              label: 'Reload',
              iconClassName: 'mdi mdi-reload',
              onClick: () => this.props.tableActions.reload(),
              show: true
            }
          ]}
          translate={false}
        />
        <div className="flex mpk-padding-N padding-all">
          {this.props.children}
        </div>
        <Form
          item={this.props.state.selectedItem}
          visible={this.props.state.showForm}
          onCancel={() => this.props.handle.showForm(false)}
        />
        <Users
          visible={this.props.state.showUser}
          item={this.props.state.selectedItem}
          onCancel={() => this.props.handle.setProperties({showUser: false, selectedItem: null})}
        />
      </div>
    )
  }
}

export default withTable((props, handle) => ({
  hint: {children: t.translate('routes.registration.hint.listText')},
  columns: [
    {
      label: t.translate('word.name'), value: 'name', className: 'mpk-min-width-N', show: true, isSearchable: true, isSortable: true, isDefaultSearchColumn: true, 
      onClick: d => handle.setProperties({showUser: true, selectedItem: d})//item => handle.showForm(true, item)
    },
    {label: 'Select', value: (item) => (
      <Button 
        raised 
        className="mpk-full width"
        primary={props.tmpObject.institution && props.tmpObject.institution.id === item.id ? true : false}
        onClick={() => {
          props.tmpObjectActions.setProperties({
            institution: item
          })
          utilsService.setReqOptionsInstitutionId(item.id)
          navService.redirectTo('/internal/registration/chart')
        }}
      >
        {props.tmpObject.institution && props.tmpObject.institution.id === item.id ? 'Monitoring' : 'Monitor'}
      </Button>
    ), type: 'func', show: true, isSearchable: false, isSortable: false},
    // {label: t.translate('word.user'), value: 'user.name', className: 'mpk-min-width-N', show: true, isSearchable: true, isSortable: true},
    // {label: t.translate('word.userEmail'), value: 'user.email', show: true, isSearchable: true, isSortable: true},
    {label: t.translate('word.modifiedAt'), value: 'dateModified', type: 'date', show: true, isSearchable: false, isSortable: true, isDefaultSort: true},
    {label: t.translate('word.modifiedBy'), value: 'modifiedBy', show: true, isSearchable: true, isSortable: true},
    {label: t.translate('word.createdAt'), value: 'dateCreated', type: 'date', show: false, isSearchable: false, isSortable: true},
    {label: t.translate('word.createdBy'), value: 'createdBy', show: false, isSearchable: true, isSortable: true},
  ],
  itemActions: [
    {
      label:"Edit",
      iconClassName:"mdi mdi-pencil",
      onClick: item => handle.showForm(true, item),
      show: props.auth.hasPermission('PUT:/institution/{id}')
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick: item => handle.deleteItem(item, institutionService.remove, {id: item.id}),
      show: props.auth.hasPermission('DELETE:/institution/{id}')
    },
  ],
  fetchData: (params) => (
    new Promise(async (resolve, reject) => {
      let { company } = props.auth;
      try {
        let res = await institutionService.get(params);
        resolve({data:res.data, total:Number(res.headers['x-pagination-count'])})
      } catch (error) {
        reject(error)
      }
    })
  ),
}))(List);