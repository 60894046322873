import React from 'react';
import { Commandbar, withTable, navService } from 'libs/react-mpk';
import fileReportService from './fileReportService';
import t from 'counterpart';

class List extends React.Component{
  showUser = item => {
    console.log(item);
  }

  render(){
    return(
      <div className="flex mpk-layout column mpk-full height">
        <Commandbar
          title={t.translate('routes.fileReport.title')}
          actions={[
            {
              label: 'Reload',
              iconClassName: 'mdi mdi-reload',
              onClick: () => this.props.tableActions.reload(),
              show: true
            }
          ]}
          translate={false}
        />
        <div className="flex mpk-padding-N padding-all">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withTable((props, handle) => ({
  hint: {children: t.translate('routes.fileReport.hint.text')},
  columns: [
    {
      label: t.translate('word.fileName'), value: 'fileName', className: 'mpk-min-width-N', show: true, isSearchable: true, isSortable: true, isDefaultSearchColumn: true, 
      onClick: async item => {
        let { company } = props.auth;
        let { asInternalService } = props.global;
        try {
          let res = await fileReportService.download(item.id, (!asInternalService && !company))
        } catch (error) {
          props.toastActions.izi(
            t.translate('word.failed'),
            'Failed download',
            'warning'
          )
        }
      }
    },
    {label: t.translate('word.status'), value: d => (
      <div className={`mpk-status ${(() => {
        switch(d.status){
          case 'completed':
            return 'success';
          case 'in-progress':
            return 'progress outline';
          case 'failed':
            return 'warn';
          default:
            return 'idle outline';
        }
      })()}`}>
        {d.status}
      </div>
    ), type:'func', valueName:'status', show: true, isSearchable: true, isSortable: true},
    // {label: t.translate('word.userEmail'), value: 'user.email', show: true, isSearchable: true, isSortable: true},
    {label: t.translate('word.createdAt'), value: 'dateCreated', type: 'date', show: false, isSearchable: false, isSortable: true, isDefaultSort: true},
    {label: t.translate('word.createdBy'), value: 'createdBy', show: false, isSearchable: true, isSortable: true},
  ],
  itemActions: [
    // {
    //   label:"Download",
    //   iconClassName:"mdi mdi-download",
    //   onClick: this.handleDownload,
    //   show: props.auth.hasPermission('GET:/report/download/{id}')
    // },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick: item => handle.deleteItem(item, fileReportService.remove, item),
      show: props.auth[props.auth.company ? 'hasCompanyPermission' : 'hasPermission']('DELETE:/report/{id}')
    },
  ],
  fetchData: (params) => (
    new Promise(async (resolve, reject) => {
      let { company } = props.auth;
      let { asInternalService } = props.global;
      try {
        let res = await fileReportService[asInternalService || company ? 'get' : 'getSupportIndex'](params);
        resolve({data:res.data, total:Number(res.headers['x-pagination-count'])})
      } catch (error) {
        reject(error)
      }
    })
  )
}))(List);