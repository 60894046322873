import React from 'react';
import t from 'counterpart';
import { DialogContainer, LoaderInfo, DividerLabel, Hint, rdxConnect } from 'libs/react-mpk';
import { Button, TextField } from 'react-md';
import registrationService from './registrationService';
import { startCase } from 'lodash';

class Details extends React.Component{
  state = {
    isLoading: true
  }

  componentDidUpdate(prevProps){
    if(!prevProps.visible && this.props.visible && this.props.item){
      this.fetchData();
    }
  }

  fetchData = async () => {
    this.setState({isLoading: true})
    try {
      let { item } = this.props;
      let res = await registrationService.getDetails(item.id);
      this.setState({data: res.data, isLoading: false})
    } catch (error) {
      this.setState({isLoading: false});
      this.props.toastActions.izi(
        t.translate('word.failed'),
        typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
        'warning'
      )
    }
  }

  render(){
    let { visible, onCancel, item} = this.props;
    let { data, isLoading } = this.state;
    return(
      <DialogContainer
        id="registration-details"
        title={t.translate('routes.registration.details')}
        visible={visible}
        onHide={onCancel}
        isLoading={this.state.isLoading}
        focusOnMount={false}
        dialogStyle={{
          width: '100%',
          maxWidth: 480
        }}
        actions={[
          <Button
            flat onClick={onCancel}
          >
            {t.translate('word.close')}
          </Button>
        ]}
      >
        { isLoading ? <LoaderInfo/> : (
          <div>
            { item && (
              <div
                className={`mpk-full width mpk-align-center mpk-status outline ${(() => {
                  switch(item.status.toLowerCase()){
                    case 'valid':
                      return 'progress'
                    case 'berhasil':
                    case 'success':
                      return 'success';
                    case 'gagal':
                      return 'warn';
                    default:
                      return 'idle'
                  }
                })()}`}
              >
                {item.status}
              </div>
            )}
            <div className="mpk-margin-M margin-bottom margin-top">
              {Object.keys(data).filter(dkey => (typeof data[dkey] !== 'object')).map( dkey => (
                <TextField
                  key={`general-${dkey}`}
                  label={startCase(dkey)}
                  defaultValue={data[dkey] || '-'}
                />
              ))}
            </div>
            {['wp', 'klu', 'klu2', 'domisili', 'ktp', 'usaha', 'persyaratan'].map( key => (
              data[key] && (
                <div className="mpk-margin-M margin-bottom">
                  <DividerLabel label={key.toUpperCase()}/>
                  {Object.keys(data[key]).map( dkey => (
                    <TextField
                      key={`${key}-${dkey}`}
                      label={startCase(dkey)}
                      defaultValue={data[key][dkey] || '-'}
                    />
                  ))}
                </div>
              )
            ))}
          </div>
        )}
      </DialogContainer>
    )
  }
}

export default rdxConnect(Details);