import React from 'react';
import { SearchField, Companies, LoaderInfo, rdxConnect, navService, Pagination } from 'libs/react-mpk';
import companyService from './companyService';
import { SelectField } from 'react-md';
import t from 'counterpart';
import _ from 'lodash';

class SearchCompany extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      hasMore: true,
      isLoading: false,
      query:{
        page: 1,
        size: 20,
        column: 'name',
        keyword: ''
      }
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  onSelectCompany = (item, subscription) => {
    if(item.subscriptions.length > 0){
      navService.redirectTo(`/company/${item.id}/${subscription.id}/registration`)
    }
  }

  fetchData = async (newQuery={}, clearData=true, isNext=false) => {
    let { data, query } = this.state;
    query = _.merge(query, newQuery);
    if(isNext) query.page += 1;
    if(clearData) {
      query.page = 1;
      data = []
    }
    this.setState({query, isLoading: true});
    try{
      let res = await companyService.get(query, item => {
        data.push(item);
        setTimeout(() => this.setState({data}), 500*data.length);
      })
      
      query.total = Number(res.headers['x-pagination-count']);
      this.setState({isLoading: false, query});
    }catch(error){
      this.props.toastActions.izi(
        t.translate('word.failed'),
        error.message[this.props.global.localeId],
        'warning'
      )
    }
  }

  onChangeColumn = value => {
    let { query } = this.state;
    query.column = value;
    this.setState({query});
  }

  render(){
    let { query, data, isLoading } = this.state;
    let searchbar = (
      <div 
        className="mpk-padding-N padding-all flex-none" 
        style={{background: 'white'}}
      >
        <div className="mpk-layout mpk-center-container flex">
          <SelectField
            id="select-company-filter"
            className="min-width-N mpk-margin-N margin-right"
            label={t.translate('custom.words.searchBy')}
            value={query.column}
            onChange={ value => this.onChangeColumn(value)}
            menuItems={[
              {label: 'Nama', value: 'name'},
              {label:'NPWP', value: 'npwp'}
            ]}
          />
          <SearchField 
            className="flex"
            label={t.translate('word.keyword')}
            placeholder={t.translate('custom.sentences.Companies')}
            onSubmit={ keyword => this.fetchData({keyword})}
          />
          <Pagination
            {...query}
            onChangePage={ page => this.fetchData({page})}
          />
        </div>
      </div>
    )

    let subscriptions = (
      <div 
        className="mpk-center-container flex mpk-content mpk-padding-N padding-all"
        id="subs-container"
      >
        { isLoading ? (<LoaderInfo/>) : (
          <Companies 
            className="mpk-animation slide-in"
            onSelectCompany={this.onSelectCompany}
            companies={data}
            ignoreSelectSubscription={false}
            footer={null}
            showGreeting={false}
          />
        )}
      </div>
    )

    return(
      <div className="search-company mpk-full height mpk-layout column">
        {searchbar}
        {subscriptions}
      </div>
    )
  }
}

export default rdxConnect(SearchCompany);