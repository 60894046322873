import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const service = {
  get: (params) => {
    let options = utilsService.generateGatewayOptions(`/report`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  getSupportIndex: (params) => {
    let options = utilsService.generateGatewayOptions(`/report/support/index`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  download: (id, isSupport, company) => {
    let options = utilsService.generateGatewayOptions(`/report${isSupport ? '/support' : ''}/download/${id}`)
    return httpService.download(options)
  },
  remove: item => {
    console.log(item)
    let options = utilsService.generateGatewayOptions(`/report/${item.id}`)
    return httpService.delete(options)
  }
};

export default service;
