import hosts from './hosts';
import numeral from 'numeral';
import terbilang from '@develoka/angka-terbilang-js';
import moment from 'moment'

let reqOptions = {
  companyId: null, 
  companyName: null,
  subscriptionId:null,
  baseUrl: null,
  clientId: null,
  client: null,
  asWidget: false,
  institutionId: null
}

const utilsService = {
  // '4/30/2018' to 1525021200000
  slashDateToMillis: (str,isStart) => {
    var h = 59, m = 59, s = 59, ms = 999;
    if(isStart){
      h = 0; m = 0; s = 0; ms = 0;
    }
    let p = str.split('/')
    let d = new Date(parseInt(p[2], 0), parseInt(p[0], 0)-1, parseInt(p[1], 0), h, m, s, ms)
    return d.getTime()
  },
  mapUrlParams: params => {
    if(params.startDate) params.startDate = moment(params.startDate).toISOString()
    if(params.endDate) params.endDate = moment(params.endDate).toISOString()
    return params;
    let newParams = {};
    let switcher = {
      page: "page",
      column: "field",
      sortBy: "column",
      sort: "order",
      size: "size",
      keyword: "query",
      startDate: "startDate",
      endDate: "endDate"
    };

    let keys = Object.keys(switcher);
    for(let i = 0 ; i < keys.length ; i++){
      let key=keys[i];
      if(params[key]){
        if(key==='startDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], true );
        }else if(key==='endDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], false );
        }else{
          newParams[switcher[key]] = params[key];
        }
      }
    }

    return newParams;
  },
  
  cloneObject: object => {
    return JSON.parse(JSON.stringify(object));
  },
  setReqOptionsBaseUrl: baseUrl => {
    reqOptions.baseUrl = baseUrl;
  },
  setReqOptionsCompanyId: id => {
    reqOptions.companyId = id;
  },
  setReqOptionsCompanyName: name => {
    reqOptions.companyName = name;
  },
  setReqOptionsClientId: clientId => {
    reqOptions.clientId = clientId;
  },
  setReqOptionsSubscriptionId: id => {
    reqOptions.subscriptionId = id;
  },
  setReqOptionsClient: client => {
    reqOptions.client = client;
  },
  setReqOptionsAsWidget: asWidget => {
    reqOptions.asWidget = asWidget;
  },
  setReqOptionsInstitutionId: institutionId => {
    reqOptions.institutionId = institutionId;
  },
  generateGatewayOptions:(url, config={headers:{}}, enabledHost=true, useDefaultBaseUrl=true) => {
    let { host, baseUrl='', applicationType } = hosts.getAll()[reqOptions.asWidget ? 'widgetGateway' : 'apiGateway'];
    config = [ null, undefined ].indexOf(config) >= 0 ? {headers:{}} : config;
    if(!config.headers) config.headers = {};
    if(reqOptions.companyId) config.headers['X-COMPANY-ID'] = reqOptions.companyId;
    if(reqOptions.companyName) config.headers['X-COMPANY-NAME'] = reqOptions.companyName;
    if(reqOptions.subscriptionId) config.headers['X-SUBSCRIPTION-ID'] = reqOptions.subscriptionId;
    if(reqOptions.client) config.headers['X-Client'] = reqOptions.client;
    if(reqOptions.clientId) config.headers['X-Client-ID'] = reqOptions.clientId;
    if(applicationType) config.headers['X-Application-Type'] = applicationType;
    if(reqOptions.institutionId) config.headers['X-Institution-ID'] = reqOptions.institutionId;

    return {
      url:enabledHost ? `${host}${reqOptions.baseUrl || (useDefaultBaseUrl ? baseUrl : '')}${url}` : url, 
      config
    }
  },
   parseNPWP:(npwp) => {
    let result = String(npwp).split('');
    result.splice(2, 0, '.');
    result.splice(6, 0, '.');
    result.splice(10, 0, '.');
    result.splice(12, 0, '-');
    result.splice(16, 0, '.');
    let regex = npwp.replace(/\./g, '').replace(/-/g,'');
    return regex.length >= 15 ? result.toString().replace(/,/g,'') : regex;
  },
  parseNumber: value => {
    return numeral(value).format('0,0').replace(/,/g, '&').replace(/./g, ',').replace(/&/g, '.')
  },
  parseTerbilang: (value, currency) => {
    value = terbilang(String(Number(value)));
    let arrValue = value.split(' ');
    for(let i = 0 ; i < arrValue.length ; i++){
      let d = arrValue[i];
      arrValue[i] = `${d.charAt(0).toUpperCase()}${d.substr(1, d.length - 1)}`;
      
    }
    return `${arrValue.toString().replace(/,/g, ' ')} ${currency ? (
      ['idr'].indexOf(currency.toLowerCase()) >= 0 ? 'Rupiah' : (`${currency.charAt(0).toUpperCase()}${currency.toLowerCase().substr(1, currency.length - 1)}`)
    ) : ''}`;
  }
};

export default utilsService;