import React from 'react';
import { DialogContainer, NotFoundData, LoaderInfo, rdxConnect } from 'libs/react-mpk';
import { Button, TextField, DataTable, TableHeader, TableBody, TableColumn, TableRow } from 'react-md';
import t from 'counterpart';
import institutionService from './institutionService';
import { find, merge } from 'lodash';
import { divide } from 'numeral';

class UserForm extends React.Component{
  state = {
    email: '',
    inProgress: false
  }

  onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({inProgress: true});
    try {
      let res = await institutionService.user.post(this.props.item.id, {email: this.state.email})
      this.setState({inProgress: false, email: ''})
      this.props.onUserAdded(res.data);
    } catch (error) {
      this.setState({inProgress: false})
      this.props.toastActions.izi(
        t.translate('word.failed'),
        typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
        'warning'
      )
    }
  }

  render(){
    let { inProgress } = this.state;
    return(
      <form 
        onSubmit={this.onSubmit} 
        className="mpk-layout align-center mpk-padding-N padding-left padding-right padding-bottom"
        style={{
          background: 'white'
        }}
      >
        <TextField
          id="user-form-institution-name"
          label={t.translate('word.name')}
          value={this.state.email}
          onChange={value => this.setState({email: value.replace(/ /g,'')})}
          type="email"
          required={true}
          disabled={inProgress}
        />
        <Button
          type="submit"
          className="flex-none"
          raised primary
          iconClassName="mdi mdi-plus"
        >
          {t.translate('word.add')}
        </Button>
      </form>
    )
  }
}

class Users extends React.Component{
  state = {
    data: [],
    isLoading: true,
    query:{
      page: 1,
      size: 20,
      keyword: '',
      column: ''
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.visible && this.props.visible && this.props.item){
      this.fetchData();
    }
  }

  fetchData = async (newQuery={}) => {
    this.setState({isLoading: true});
    let query = merge(this.state.query, newQuery);
    try {
      let res = await institutionService.user.get(this.props.item.id, query);
      let total = Number(res.headers['x-pagination-count']);
      this.setState({data: res.data, query, isLoading: false, total})
    } catch (error) {
      this.setState({isLoading: false})
      this.props.toastActions.izi(
        t.translate('word.failed'),
        typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
        'warning'
      )
    }
  }

  onUserAdded = user => {
    let { data, total } = this.state;
    this.setState({data: [user, ...data], total: total+1})
  }

  onDeleteUser = user => {
    this.props.dialogActions.showConfirm(
      t.translate('sentence.dialog.delete.title'),
      t.translate('sentence.dialog.delete.message').replace('*item', user.email),
      async (callback) => {
        try {
          await institutionService.user.delete(this.props.item.id, user.id);
          let { data } = this.state;
          let userItem = find(data, {id: user.id});
          if(userItem) data.splice(data.indexOf(userItem), 1);
          this.setState({data});
          callback()
        } catch (error) {
          callback(true, typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message)
        }
      }
    )
  }

  render(){
    let { data, isLoading } = this.state;
    let { item, visible, onCancel } = this.props;
    const columns = [
      {
        label: t.translate('word.name'),
        value: d => (<div className="mpk-min-width-N">{d.name}</div>)
      },
      {
        label: t.translate('word.email'),
        value: d => (d.email)
      },
      {
        label: t.translate('word.username'),
        value: d => (d.username)
      },
      {
        label: t.translate('word.delete'),
        value: d => (
          <Button
            icon
            iconClassName="mdi mdi-delete"
            onClick={() => this.onDeleteUser(d)}
          />
        )
      }
    ]

    return(
      <DialogContainer
        id="institution-user-list"
        title={t.translate('routes.institution.userList')}
        visible={visible}
        onHide={onCancel}
        focusOnMount={false}
        dialogStyle={{
          width: '100%',
          maxWidth: 640
        }}
        actions={[
          <Button 
            flat
            onClick={onCancel}
          >
            {t.translate('word.close')}
          </Button>
        ]}
      >
        <UserForm
          {...{
            item,
            global: this.props.global,
            toastActions: this.props.toastActions
          }}
          onUserAdded={this.onUserAdded}
        />
        { isLoading ? ( <LoaderInfo/> ) : (
          data.length === 0 ? (
            <NotFoundData/>
          ) : (
            <DataTable
              plain 
              baseId={`user-list-table-${item ? item.id : '0'}`}
            >
              <TableHeader>
                <TableRow>
                  {columns.map(col => (
                    <TableColumn
                      key={`user-${col.label}`}
                    >
                      {col.label}
                    </TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map(d => (
                  <TableRow
                    key={`user-row-${d.id}`}
                  >
                    {columns.map(col => (
                      <TableColumn
                        key={`user-row-${d.id}-${col.label}`}
                      >
                        {col.value(d)}
                      </TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
          )
        )}
      </DialogContainer>
    )
  }
}

export default rdxConnect(Users);