import React from 'react';
import { withTabs } from 'libs/react-mpk';
import Chart from './Chart';
import List from './List';

class Registration extends React.Component{
  render(){
    return(
      <div className="mpk-full height width mpk-layout column">
        {this.props.children}
      </div>
    )
  }
}

export default withTabs(props => ({
  tabs: [
    {
      label: 'Chart',
      key: 'chart',
      component: <Chart/>
    },
    {
      label: 'List',
      key: 'list',
      component: <List/>
    }
  ],
  onTabChange: (tab) => {
    let { company, subscription } = props.auth;
    let { asInternalService } = props.global;
    props.router.history.push( company 
      ? `/company/${company.id}/${subscription.id}/registration/${tab.key}`
      : `/${asInternalService ? 'internal' : 'support'}/registration/${tab.key}`
    )
  },
}))(Registration)