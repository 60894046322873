import React, { Component } from 'react';
import Router from 'react-router-dom/Router';
import { Appbar, Sidebar, CurrentCompany, NotFoundData, rdxConnect, LoaderInfo, navService } from 'libs/react-mpk';
import { utilsService } from 'services';
import companyService from './companyService';
import t from 'counterpart';
import { find } from 'lodash';

import routes from './routes';

class Company extends Component{
  constructor(){
    super();
    this.state = {
      isLoading: true
    }
  }
  componentWillMount(){
    this.props.authActions.setProperties({
      company: null,
    });
  }

  componentDidMount(){
    this.getCompany();
  }

  getCompany = async (callback=() => {}) => {
    let { companyId, subscriptionId } = this.props.router.match.params;
    let { auth } = this.props;
    
    utilsService.setReqOptionsCompanyId(companyId);
    utilsService.setReqOptionsSubscriptionId(subscriptionId);
    
    try{
      let res = await companyService.me(companyId, subscriptionId, auth.user.isSupport ? 'support' : 'user');
      let { company } = res.data;
      let allPath = [];//res.data.menus[0].children.filter( d => (d.path !== '/main'));
      for(let menu of res.data.menus){
        if(menu.children) allPath = allPath.concat(menu.children);
      }
      const defaultPath = allPath.length > 0 ? allPath[0] : null;
      const currentPath = window.location.pathname;
      const _currentPath = find(allPath, {path: currentPath});

      const _set = () => {
        let meInCompany = res.data;
        utilsService.setReqOptionsCompanyName(company.name);
        let { subscription } = meInCompany;
        this.props.authActions.setProperties({
          company, meInCompany, subscription,
          hasCompanyPermission: resource => {
            return meInCompany.permission.indexOf(resource) >= 0;
          },
          hasOrganizationPermission: (npwp, resource)  => {
            let target =  meInCompany.organizations[npwp];
            return target ? (
              target.permissions.indexOf(resource) >= 0 ? true : false
            ) : false;
          }
        });

        this.setState({isLoading: false});
      }

      if(_currentPath) _set();
      else if(defaultPath){
        _set();
        navService.redirectTo(defaultPath.pathName);
      } else {
        navService.redirectTo('/main');
        this.props.toastActions.izi(
          'Forbidden',
          t.translate('custom.sentences.notPermittedCompany').replace('*name*', company.name),
          'warning'
        )
      }
      callback();
    } catch(error){
      console.log(error);
      callback();
      if(error){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          typeof error.message === 'object' ? error.message[this.props.global.loceleCode] : error.message,
          'warning'
        )
      }
    }
  }

  render(){
    let { isLoading } = this.state;
    let { auth, global } = this.props;
    let { subscription } = auth;
    this.props.global.username = auth.user.username;
    return(
      <div className="mpk-layout column fill mpk-full viewport-width viewport-height">
        <Appbar
          subname="BKWP Monitoring"
          showVersion fixedVersion={false}
          profileMenu={[
            {
              label:"Profile",
              materialIcon:'perm_identity',
              onClick:() => {console.log('bisa')}
            }
          ]}
          appsShortcut={auth.user.apps}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
            return this.props.global.appConsole[key];
          })}
          translate={true}
        />
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            isLoading={!auth.company}
            menus={auth.meInCompany ?  [...auth.meInCompany.menus, {
              label: {
                id: 'Navigasi',
                en: 'Navigation'
              },
              children:[
                {
                  label:{
                    id:'Kembali',
                    en:'Back'
                  },
                  'iconClassName':'mdi mdi-arrow-left',
                  'path': auth.user.isSupport ? '/support/companies' : '/main'
                }
              ]
            }] : []}
            getValueByLocaleCode={true}
            collapsible={false}
            headerElements={ auth.company ? (
              <CurrentCompany
                userAccountUrl={global.appConsole.userAccount.url}
                onReloadProfile={ callback => this.getCompany(false, callback)}
              /> 
            ) : null}
          />
          { isLoading ? (
            <div className="flex">
              <LoaderInfo text="getting company data..."/>
            </div>
          ) : (
            auth.company ? (
              <Router history={this.props.router.history}>
                {routes()}
              </Router>
            ) : (<NotFoundData/>)
          )}
        </div>
      </div>
    )
  }
}

export default rdxConnect(Company);