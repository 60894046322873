import React from 'react';
import registrationService from './registrationService';
import { LoaderInfo, rdxConnect, Hint, NotFoundData } from 'libs/react-mpk';
import moment from 'moment';
import t from 'counterpart';
import Plot from 'react-plotly.js';
import { Paper, Button } from 'react-md';
import Period from '../../components/Period';
import { merge } from 'lodash';

class Chart extends React.Component{
  state = {
    showPeriod: false,
    isLoading: true, 
    downloadingReport: false,
    query:{
      startDate: null, 
      endDate: null
    },
    resData: {},
    data: {}
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async (newQuery={}) => {
    let { company } = this.props.auth;
    let { asInternalService } = this.props.global;
    let query = merge(this.state.query, newQuery);
    this.setState({query, isLoading: true});
    try {
      let res = await registrationService[ asInternalService || company ? 'getByStatus' : 'getSupportByStatus']({
        startDate: moment(query.startDate).toISOString(),
        endDate: moment(query.endDate).toISOString()
      });
      let { startDate, endDate, result } = res.data;
      query.startDate = moment(startDate);
      query.endDate = moment(endDate);

      let data = {
        values: [],
        labels: [],
        raw: []
      }
      
      for(let d of result){
        data.labels.push(d.status)
        data.values.push(Number(d.count))
        data.raw.push(d)
      }

      this.setState({ isLoading: false, data, query });
    } catch (error) {
      if(error && error.message){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
          'warning'
        )    
      }
    }
  }

  stringDivider = (str, width, spaceReplacer) => {
    if (str.length>width) {
        var p=width
        for (;p>0 && str[p]!=' ';p--) {
        }
        if (p>0) {
            var left = str.substring(0, p);
            var right = str.substring(p+1);
            return left + spaceReplacer + this.stringDivider(right, width, spaceReplacer);
        }
    }
    return str;
  }

  downloadReport = async () => {
    let { startDate, endDate } = this.state.query
    this.setState({downloadingReport: true})
    try {
      let res = await registrationService.downloadReport({
          startDate, endDate
        }, 
        this.props.auth.user.isSupport, 
        this.props.auth.company
      )
      this.props.toastActions.izi(
        t.translate('word.info'),
        res.data,
        'success'
      )
      this.setState({downloadingReport: false})
    } catch (error) {
      this.setState({downloadingReport: false})
      if(error && error.message){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          typeof error.message === 'object' ? error.message[this.props.global.localeCode] : error.message,
          'warning'
        )    
      }
    }
  }

  render(){
    let { data, query } = this.state;
    return(
      <div className={`mpk-full width mpk-layout column ${data.raw && data.raw.length === 0 ? 'mpk-full height' : ''}`}>
        <Hint>
          <div className="mpk-layout align-center justify-between flex">
            <span>
              {t.translate('routes.registration.hint.text')}
              {query.startDate && query.endDate ? ` ${moment(query.startDate).format('ll')} - ${moment(query.endDate).format('ll')}` : '...'}
            </span>
            <div className="mpk-layout align-center flex-none">
              <Button
                raised primary
                className="flex-none"
                onClick={() => this.setState({showPeriod: true})}
              >
                {t.translate('sentence.changePeriod')}
              </Button>
              {(this.props.auth.user.isSupport || this.props.auth.hasPermission('GET:/report/export')) && (
                <Button 
                  raised
                  className="mpk-margin-N margin-left margin-right flex-none"
                  iconClassName="mdi mdi-download"
                  onClick={this.downloadReport}
                  disabled={this.state.downloadingReport}
                >
                  {t.translate('sentence.downloadReport')}
                </Button>
              )}
            </div>
          </div>
        </Hint>
        { this.state.isLoading ? <LoaderInfo/> : (
          data.raw.length === 0 ? (
            <div className="mpk-layout align-center justify-center flex">
              <NotFoundData
                message={'routes.registration.notFound.message'}
                todo={'routes.registration.notFound.description'}
              />
            </div>
          ) : (
            <div className="mpk-padding-N padding-all mpk-center-container">
              <div className="mpk-layout wrap mpk-margin-N margin-bottom">
                {data.raw.map((d,i) => (
                  <Paper 
                    style={{
                      minWidth: 240
                    }}
                    className={`
                      flex panel-chart mpk-padding-N padding-all mpk-margin-S
                      ${i > 0 ? 'margin-left' : ''}
                      ${i < data.raw.length - 1  ? 'margin-right' : ''}
                    `}
                  >
                    <div className="mpk-font-color-D3">{d.status}</div>
                    <div className="mpk-font-size-L mpk-margin-S margin-top">{d.count}</div>
                  </Paper>
                ))}
              </div>
              <Paper className="mpk-full width mpk-padding-N padding-all">
                <Plot
                  className="mpk-full width"
                  data={[{
                    ...data,
                    type:'pie'
                  }]}
                  layout={{
                    title: 'Persentase Registrasi',
                    autosize: true,
                    height: 480,
                    yaxis: {
                      title: 'Jumlah'
                    },
                    xaxis:{
                      tickangle: 0,
                    },
                    showlegend: false
                  }}
                />
              </Paper>
            </div>
          )
        )}
        <Period
          visible={this.state.showPeriod}
          onCancel={() => this.setState({showPeriod: false})}
          query={this.state.query}
          onSubmit={ newQuery => this.fetchData(newQuery)}
        />
      </div>
    )
  }
}

export default rdxConnect(Chart);