import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';
import { moment } from 'moment'
import qs from 'query-string';

const service = {
  get: (params) => {
    let options = utilsService.generateGatewayOptions(`/registration`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  getByStatus: (params) => {
    let options = utilsService.generateGatewayOptions(`/registration/stat/by-status`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  getDetails: id => {
    let options = utilsService.generateGatewayOptions(`/registration/${id}`)
    return httpService.get(options);
  },
  getSupportIndex: (params) => {
    let options = utilsService.generateGatewayOptions(`/registration/support/index`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  getSupportByStatus: (params) => {
    let options = utilsService.generateGatewayOptions(`/registration/support/stat/by-status`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  downloadReport: (params, isSupport, company) => {
    let options = utilsService.generateGatewayOptions(`/report${isSupport ? '/support' : ''}/export`)

    let period = `${params.startDate.format('YYMMDD')}-${params.endDate.format('YYMMDD')}`
    // options.params = utilsService.mapUrlParams(params);
    options.url = `${options.url}?${qs.stringify(utilsService.mapUrlParams(params))}`;
    // return httpService.downlgetoad(
    //   options, 'get', 
    //   isSupport 
    //     ? (company ? `${company.name}-${period}.xlsx` : `All-${period}.xlsx`)
    //     : null
    // )
    return httpService.get(options)
  }
};

export default service;
