import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const service = {
  get: (params) => {
    let options = utilsService.generateGatewayOptions(`/institution`)
    options.params = utilsService.mapUrlParams(params);
    return httpService.get(options);
  },
  post: (data) => {
    let options = utilsService.generateGatewayOptions(`/institution`)
    options.data = data;
    return httpService.post(options);
  },
  put: (id, data) => {
    let options = utilsService.generateGatewayOptions(`/institution/${id}`)
    options.data = data;
    return httpService.put(options);
  },
  remove: (item) => {
    let options = utilsService.generateGatewayOptions(`/institution/${item.id}`)
    return httpService.delete(options);
  },
  user:{
    get: (id, params) => {
      let options = utilsService.generateGatewayOptions(`/institution/${id}/user`)
      options.params = params;
      return httpService.get(options);
    },
    post: (id, data) => {
      let options = utilsService.generateGatewayOptions(`/institution/${id}/user`)
      options.data = data;
      return httpService.post(options);
    },
    delete: (id, userId) => {
      let options = utilsService.generateGatewayOptions(`/institution/${id}/user/${userId}`)
      return httpService.delete(options);
    }
  }
};

export default service;
