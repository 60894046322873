import React from 'react';
import { withTable } from 'libs/react-mpk';
import registrationService from './registrationService';
import t from 'counterpart';
import Details from './Details';

class List extends React.Component{
  render(){
    return(
      <div className="flex mpk-layout column mpk-full height mpk-padding-N padding-all">
        {this.props.children}
        <Details
          visible={this.props.state.showForm}
          onCancel={() => this.props.handle.showForm(false)}
          item={this.props.state.selectedItem}
        />
      </div>
    )
  }
}

export default withTable((props, handle) => ({
  hint: {children: t.translate('routes.registration.hint.listText')},
  columns: [
    {label: t.translate('word.noId'), value: 'wp.noIdWp', show: true, isSearchable: true, isSortable: true, isDefaultSearchColumn: true, onClick: item => handle.showForm(true, item)},
    {label: t.translate('word.name'), value: 'wp.namaWp', show: true, className:'mpk-min-width-N mpk-padding-S padding-top padding-bottom', isSearchable: true, isSortable: true},
    {label: t.translate('word.ip'), value: 'ip', show: true, isSearchable: true, isSortable: true},
    {label: t.translate('word.npwp'), value: 'npwp', show: true, isSearchable: true, isSortable: true},
    {
      label: t.translate('word.status'), value: d => (
        <div className="mpk-padding-S padding-top padding-bottom">
          <div
            className={`mpk-full width mpk-align-center mpk-max-width-N mpk-status outline ${(() => {
              switch(d.status.toLowerCase()){
                case 'valid':
                  return 'progress'
                case 'berhasil':
                case 'success':
                  return 'success';
                case 'gagal':
                  return 'warn';
                default:
                  return 'idle'
              }
            })()}`}
          >
            {d.status}
          </div>
        </div>
      ), valueName: 'status', type: 'func', className:'mpk-font-size-S uppercase', 
      show: true, isSearchable: true, isSortable: true
    },
    {label: t.translate('word.message'), value: 'message', className:'mpk-font-size-S mpk-min-width-M mpk-max-width-M mpk-margin-S margin-top margin-bottom', show: true, isSearchable: true, isSortable: true},
    {label: t.translate('word.createdAt'), value: 'createdAt', type: 'date', show: true, isSearchable: false, isSortable: true, isDefaultSort: true},
    {label: t.translate('word.user'), value: 'user', show: true, isSearchable: true, isSortable: true},
  ],
  fetchData: (params) => (
    new Promise(async (resolve, reject) => {
      let { company } = props.auth;
      let { asInternalService } = props.global;
      try {
        let res = await registrationService[asInternalService || company ? 'get' : 'getSupportIndex'](params);
        resolve({data:res.data, total:Number(res.headers['x-pagination-count'])})
      } catch (error) {
        reject(error)
      }
    })
  ),
  actions:[
    {
      label: 'Reload',
      iconClassName: 'mdi mdi-reload',
      onClick: () => props.tableActions.reload(),
      show: true
    }
  ],
  tableSettingProps:{
    showPeriods: true
  }
}))(List);